






































	import { Vue, Component } from 'vue-property-decorator'
	import axios from 'axios'
	import { apiBuilder } from '../../config'

	@Component
	export default class ResetPassword extends Vue {
		private rules = {
			required: (value: string | number) => !!value || 'Không Được Trống.',
		}

		private formData = {
            code: '',
            password: '',
            cfPassword: '',
            username: ''
		}

        private loading = false
        
        //COMPUTED
		get passwordConfirmationRule() {
			return this.formData.password === this.formData.cfPassword || 'Mật Khẩu Không Trùng'
		}

		private sendRequest() {
			const form = this.$refs.form as Vue & { reset: () => boolean; validate: () => boolean }

			if (form.validate()) {
				this.loading = true

				const apiUrl = apiBuilder('account/forgotpassword.php')

				const param = {
					params: {
                        username: this.formData.username,
                        code: this.formData.code,
                        password: this.formData.password,
                        confirmpassword: this.formData.cfPassword
					},
				}

				axios
					.post(apiUrl, null, param)
					.then((res) => {
						const error = res.data.error

						if (error == '251') {
							return this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Tên Đăng Nhập Không Tồn Tại !',
							})
						} else if (error == '252') {
							return this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Code Lỗi Hoặc Hết Hạn !',
							})
						} else if (error == '253') {
							return this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Nhập Lại Mật Khẩu Không Đúng !',
							})
						} else if (error != '250') {
							return this.$swal({
								icon: 'error',
								title: 'Lỗi !',
								text: 'Server Error ! Please Reload',
							})
						}

						form.reset()

						this.$swal({
							icon: 'success',
							title: 'Thành Công !',
							text: 'Đặt Lại Mật Khẩu Thành Công !',
						}).then(() => {
							this.$router.replace({ name: 'Login' })
						})

						setTimeout(() => {
							this.$router.replace({ name: 'Login' })
						}, 1000)
					})
					.catch((e) => {
						return this.$swal({
							icon: 'error',
							title: 'Lỗi !',
							text: e,
						})
					})

				this.loading = false
			}
		}
	}
